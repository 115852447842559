/* .App {
  text-align: center;
} */

.content {
  margin-top: 3em;
  margin-bottom: 2em;
  min-height: calc(100vh - 204px);
}

.content a, .content .link {
  text-decoration: none;
  color: #F9640E;
  font-weight: 600;
  cursor: pointer;
  transition: all 250ms ease-in-out;
}

.content a:hover, .content .link:hover {
  color: #ffffff;
  background-color: #F9640E;
  transition: all 150ms ease-in-out;
}

.App a {
  text-decoration: none;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .nav-link {
  margin-left: 24px;
} */

.nav-link.selected .MuiTypography-button, .nav-link:hover .MuiTypography-button {
  border-bottom: 2px solid #ffffff;
  transition: border 0.3s ease-in-out;
}

.nav-link .MuiTypography-button {
  border-bottom: 2px solid transparent;
  color: #ffffff;
  transition: border 0.3s ease-in-out;
  padding: 8px 0;
}

img.thumbnail {
  display: block;
  height: auto;
  max-width: 100%;
}

footer {
  padding: 16px;
  position: static;
	bottom: 0;
	width: 100%;
}

.footer-link {
  color: rgba(0, 0, 0, 0.54);
  transition: color 0.3s ease-in-out;
}

.footer-link:hover {
  color: #F9640E;
  transition: color 0.2s ease-in-out;
}

.footer-icon:hover {
  color: #F9640E;
  transition: color 0.2s ease-in-out;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
